import buildDefaultActions from "./actions"
import buildDefaultMutations from "./mutations"
import buildDefaultState from "./state"
import buildDefaultGetters from "./getters"

import { defaultMapFiltersMethod } from "./helpers"
import { mapValues, cloneDeep } from "lodash-es"
import { bus } from "@/config"

class StoreItemsModule {
  constructor({
    baseURI,
    presetActions = [],
    withFilters = false,
    withPagination = false,
    withSorting = false,
    mapFilters = defaultMapFiltersMethod
  } = {}) {
    this.config = {
      baseURI,
      presetActions: presetActions,
      withFilters: withFilters,
      withPagination: withPagination,
      withSorting: withSorting,
      mapFilters: mapFilters
    }
    this.state = buildDefaultState(this.config)
    this.mutations = buildDefaultMutations(this.config)
    this.actions = buildDefaultActions(this.config)
    this.getters = buildDefaultGetters()
  }

  mergeState(state = {}) {
    this.state = { ...this.state, ...state }
  }

  mergeGetters(getters = {}) {
    this.getters = { ...this.getters, ...getters }
  }

  mergeMutations(mutations = {}) {
    this.mutations = { ...this.mutations, ...mutations }
  }

  mergeActions(actions = {}, wrapper = undefined) {
    const newActions = wrapper ? mapValues(actions, wrapper) : actions

    this.actions = { ...this.actions, ...newActions }
  }

  inject({ store, name }) {
    const injectedStore = store.state[name]
    if (!injectedStore) {
      store.registerModule(name, this.toVuex())

      bus.on(`reset-${name}-state`, () => {
        store.commit(`${name}/RESET_STATE`, this.state)
      })
    }
  }

  toVuex() {
    return {
      namespaced: true,
      state: cloneDeep(this.state),
      mutations: this.mutations,
      actions: this.actions,
      getters: this.getters
    }
  }
}

export default StoreItemsModule
